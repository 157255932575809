import CardContainer from "./pages/CardPage";
import Navbar from "./components/Navbar";
import AboutPage from "./pages/AboutPage";
import "./styles/styles.scss"
import "./styles/responsive.scss"
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<CardContainer />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </>
  );
};
export default App;
