import React from "react";

export default function Card(props) {
  const backgroundClass = `${props.background.replace(" ","")}-background`;



  return (
    <article className="card">
      <div className="top-bar">
        <h3 id="type">{props.type}</h3>
        <div id="divide"></div>
        <h3>question</h3>
      </div>

      <div id="indicator" className={backgroundClass}></div>

      <div className="main-section">
        <h1 id="questionText">{props.text}</h1>
      </div>
    </article>
  );
}
