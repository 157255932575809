import React, { useState, useEffect } from "react";

export default function WelcomeCard(props) {
  const [Instruction, setInstruction] = useState("Click left or right for a random check-in");

  useEffect(() => {
    if(window.screen.width <= 1025){
      setInstruction("Swipe for a random check-in")
    }
    console.log("Test")
  }, []);

 
  return (
    <>
      <div className="card">
        <div className="welcomeTitle">
          <h3>Check-in generator</h3>
          <h1>build trust, <br />build relationships </h1>
          
          
        </div>
        <div className="welcomeCopy">
          <p>
          Check-ins create better meetings. It’s backed by science, and anyone can do it. 
          </p>
        </div>

        <div className="startSwipe">
          <p>{Instruction}</p>
    
        </div>
      </div>
    </>
  );
}
