import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {

  // const notify = () => toast("mail was copied!");
  const notify = () => toast.success('Mail was copied!', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  const mail = 'contact@daresay.co'
  return (
    <div id="footer">
      <div id="footer-left">
        <h1>Daresay</h1>
        <div className="footerContact">
          <p>The Check-in Generator is created and run by Daresay. We love feedback and collaboration. So don't be a stranger, get in touch with us!</p>
          <button onClick={() => {
            navigator.clipboard.writeText(mail); notify()}}>contact@daresay.co</button>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </div>
      <div className="divider"></div>
      <div id="footer-right">
        <h3>Other tools by the Daresay team</h3>
        <div id="tools">
          <a href="https://hubs.la/H0LfjNp0">Teamwork Kit</a>
          <a href="https://hubs.ly/Q02fNHm80">AI-Sprint Kit</a>
          <a href="https://www.figma.com/community/file/852539816376323688">Mobile UI Kit</a>
          <a href="https://a11y.daresay.io/">Accessibility Checklist</a>
        </div>

      </div>

    </div>
  );
}
