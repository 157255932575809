import React from "react";
import Scene from "../components/AnimatedBackground";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

const backGroundColors = {
  bgColor: "#FDFBF7",
  colorA: "#FCF4F2",
  colorB: "#FCF8F3",
};
const AboutPage = () => {
  return (
    <>
    <div className="aboutContainer">

      <div className="card aboutCard">
        <h1>The check-in generator</h1>
        <p>At Daresay we use check-ins to start meetings and workshops. This gives us room to remind ourselves that we're all human. </p>
        <p>A quick check-in doesn't just lighten the mood, it also helps us focus on what we’re doing as a team.</p>
        <p>However, spontaneous variety is hard and trying to think of new check-ins can be exhausting. Therefore, we have collected check-ins from the web and our colleagues and turned them into this check-in generator. </p>
        <p>Get swiping and feel free to share feedback and new check-ins with us!</p>
        <Contact />
      </div>
    
      <div className="aboutAnimation">
        <Scene colors={backGroundColors} />
      </div>
       
    </div>
    <Footer />
    </>

    
  );
};

export default AboutPage;
