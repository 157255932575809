import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { Color } from "three";
import VertexShader from "../VertexShader";
import FragmentShader from "./FragmentShader";

const Gradient = (props) => {
  const mesh = useRef();
  const { viewport } = useThree();

  const uniforms = useMemo(
    () => ({
      u_time: { value: 0.0 },
      u_bg: { value: new Color("#61B597") },
      u_colorA: { value: new Color("#7ECB9A") },
      u_colorB: { value: new Color("#308D91") },
    }),
    []
  );

  useFrame((state) => {
    const { clock } = state;

    mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();
  });

  useEffect(() => {
    mesh.current.material.uniforms.u_bg.value = new Color(props.colors.bgColor);
    mesh.current.material.uniforms.u_colorA.value = new Color(props.colors.colorA);
    mesh.current.material.uniforms.u_colorB.value = new Color(props.colors.colorB);
  }, [props.colors]);

  return (
    <mesh
      ref={mesh}
      position={[0, 0, 0]}
      scale={[viewport.width / 3.5, viewport.height / 3.5, 1.0]}
    >
      <planeGeometry args={[5, 5, 32, 32]} />
      <shaderMaterial
        fragmentShader={FragmentShader}
        vertexShader={VertexShader}
        uniforms={uniforms}
        wireframe={false}
      />
    </mesh>
  );
};

const Scene = (props) => {
  return (
    <Canvas camera={{ position: [0.0, 0.0, 2] }}>
      <Gradient
       
        colors={props.colors}
      />
    </Canvas>
  );
};

export default Scene;
