import React from "react";

export default function Navbar() {
  return (
    <>
      <nav className="navbar">
        <div id="navLeft">
          <div id="leftOne">
            <a href="/">
              <h1>DARESAY</h1>
              <h3>Check-in generator</h3>
            </a>
          </div>
        </div>
        <div id="navRight">
          <a href="/about">About</a>
        </div>
      </nav>
    </>
  );
}
