import React, { useState, useEffect, useRef } from "react";

export default function Filter(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMoodButton, setActiveMoodButton] = useState("all");
  const [activeCardType, setActiveCardType] = useState("Questions");
  const keywords = ["Caring", "Daring", "Laid back", "Work focused"];

  const firstButtonRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      firstButtonRef.current.focus();
    }
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleActiveMood = (keyword) => {
    setActiveMoodButton(keyword);
  };

  const handleActiveCardType = (keyword) => {
    setActiveCardType(keyword);
    setActiveMoodButton("all");
    props.setSelectedKeyword("");
  };

  const emojiSelector = (keyword) => {
    return `/images/${keyword.toLowerCase().replace(" ", "")}Emoji.png`;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && event.target.closest(".filterMenu") === null) {
        setIsOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className={`filterMenu ${isOpen ? "open" : ""}`}>
        {isOpen && (
          <>

            <div className="filterMoods" role="menu">
              <div className="filterCopy">
                <h3>Mood</h3>
                <p>What type of atmosphere do you want to create?</p>
              </div>

              <div className="moodButtons">
                <button
                  ref={firstButtonRef}
                  key="all"
                  onClick={() => {
                    props.setSelectedKeyword("");
                    handleActiveMood("all");
                  }}
                  className={
                    activeMoodButton === "all"
                      ? "filterButton-active"
                      : "filterButton"
                  }
                >
                  All
                  <img src={emojiSelector("All")} alt="" />
                </button>

                {keywords.map((keyword) => (
                  <button
                    key={keyword}
                    onClick={() => {
                      props.setSelectedKeyword(keyword);
                      handleActiveMood(keyword);
                    }}
                    className={
                      activeMoodButton === keyword
                        ? "filterButton-active"
                        : "filterButton"
                    }
                  >
                    {keyword}
                    <img src={emojiSelector(keyword)} alt="" />
                  </button>
                ))}
              </div>
            </div>
          </>
        )}

        <div className="filterToggle-container">
          <button
            aria-roledescription={
              isOpen
                ? "Close menu for filtering cards by their categories"
                : "Expand menu where you can filter cards by their categories"
            }
            className="filterToggle"
            onClick={handleClick}
          >
            {isOpen ? "Hide filter" : "Filter cards"}
          </button>
        </div>
      </div>
    </>
  );
}
