import React from "react";

export default function Contact() {
    return (
      <>
        <div className="contact">
            <img src="/images/contactPerson.png" alt="" />
            <div className="contactInfo">
                <p>Oscar Norrman</p>
                <a href="mailto:oscar.norrman@daresay.co">oscar.norrman@daresay.co</a>
            </div>
        </div>
      </>
    );
  }