import React, { useState, useEffect } from "react";

export default function ActivityCard(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [rotateChevron, setRotateChevron] = useState(false);
  const [expandCard, setExpandCard] = useState(false);

  const classNames = (n) => {
    var name = [];
    switch (n) {
      case "time":
        name = ["time"];
        if (props.time === 0) {
          name = name + " hidden";
        }
        return name;
      case "source":
        name = ["source"];
        if (props.source === 0) {
          name = name + " hidden";
        }
        return name;
      case "tip":
        name = ["tip"];
        if (props.tip === 0) {
          name = name + " hidden";
        }
        return name;
      case "instructions":
        name = ["instructions"];
        if (props.instructions === 0) {
          name = name + " hidden";
        }
        return name;
      case "setup":
        name = ["setup"];
        if (props.setup === 0) {
          name = name + " hidden";
        }
        return name;
      default:
        return "";
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    setRotateChevron(!rotateChevron);
    setExpandCard(!expandCard);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && event.target.closest(".readMore-btn") === null) {
        setIsOpen(false);
        setRotateChevron(false);
        setExpandCard(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const getBackgroundClass = (keywords) => {
    switch (keywords) {
      case "work focused":
        return "workfocused-background";
      case "caring":
        return "caring-background";
      case "energetic":
        return "energetic-background";
      case "daring":
        return "daring-background";
      case "superdaring":
        return "daring-background";
      case "laid back":
        return "laidback-background";
      default:
        return "";
    }
  };

  return (
    <>
      <article className="card">
        <div className="top-bar">
          <h3 id="type">{props.type}</h3>
          <div id="divide"></div>
          <h3>activity</h3>
        </div>

        <div id="indicator" className={getBackgroundClass(props.background)} />

        <div className="main-section">
          <h1 id="title">{props.title}</h1>
          <p id="description">{props.text}</p>
        </div>

        <div className="flex-group">
          <div className={classNames("setup")}>
            <h3>setup</h3>
            <p>{props.setup}</p>
          </div>

          <div className={classNames("time")}>
            <h3>time</h3>
            <p>{props.time}</p>
          </div>
        </div>

        <div className={`expandable-view ${expandCard ? "expanded" : ""}`}>
          <div className={classNames("instructions")}>
            <h3>How it works</h3>
            {props.instructions && (
              <ul>
                {props.instructions.map((instruction) => (
                  <li>{instruction}</li>
                ))}
              </ul>
            )}
          </div>

          <div className={classNames("tip")}>
            <h3>remote tips</h3>
            <p>{props.tip}</p>
          </div>

          <div className={classNames("source")}>
            <h3>source</h3>
            <a href={props.sourceUrl} target="_blank" rel="noopener noreferrer">
              <p>{props.source}</p>
              <img src="/images/link.png" alt="" />
            </a>
          </div>
        </div>

        <div className="readMore">
          <div className="line" />
          <button className="readMore-btn" onClick={() => handleClick()}>
            {isOpen ? "Read less" : "Read more"}
            <img
              className={`readmore-chevron ${rotateChevron ? "rotate" : ""}`}
              src="/images/chevron-down.png"
              alt=""
            />
          </button>
        </div>
      </article>
    </>
  );
}
