import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import Card from "../components/Card";
import cardData from "../data.json";
import { virtualize } from "react-swipeable-views-utils";
import WelcomeCard from "../components/WelcomeCard";
import { mod } from "react-swipeable-views-core";
import Filter from "../components/Filter";
import Scene from "../components/AnimatedBackground";
import ActivityCard from "../components/ActivityCard";

const EnhancedSwipeableViews = virtualize(SwipeableViews);
// var cards = cardData.questions.sort(() => (Math.random() > .5) ? 1 : -1);
var questionsCard = cardData.questions.sort(() =>
  Math.random() > 0.5 ? 1 : -1
);
var activitysCard = cardData.questions.sort(() =>
  Math.random() > 0.5 ? 1 : -1
);

const CardContainer = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isWelcomeCardShowing, setIsWelcomeCardShowing] = useState(true);
  const [selectedKeyword, setSelectedKeyword] = useState("All");
  const [filteredCards, setFilteredCards] = useState(questionsCard);

  // const [colorArray, setColorArray] = useState({
  //   bgColor: "#FFF1DC",
  //   colorA: "#FFEDE0",
  //   colorB: "#FFF5DC",
  // });

  //   const [colorArray, setColorArray] = useState({
  //   bgColor: "#FDE5D4",
  //   colorA: "#FFEDE0",
  //   colorB: "#FFF5DC",
  // });

  const [colorArray, setColorArray] = useState({
    bgColor: "#FFEAD9",
    colorA: "#F8EDDE",
    colorB: "#F0D8C3",
  });

  // const [colorArray, setColorArray] = useState({
  //   bgColor: "#FDE8C9",
  //   colorA: "#FFEFCC",
  //   colorB: "#FFDCC8",
  // });
  


  const [isQuestions, setIsQuestions] = useState(true);

  const handleChangeIndex = (index) => {
    setCurrentCardIndex(mod(index, filteredCards.length));

    if (index !== 0){
      setIsWelcomeCardShowing(false);
    }
  };

  const handlePrev = () => {
    setIsWelcomeCardShowing(false);
    setCurrentCardIndex(mod(currentCardIndex - 1, filteredCards.length));
  };

  const handleNext = () => {
    setIsWelcomeCardShowing(false);
    setCurrentCardIndex(mod(currentCardIndex + 1, filteredCards.length));
  };

  const detectKey = (e) => {
    if (e.key === "ArrowRight") {
      handleNext();
    } else if (e.key === "ArrowLeft") {
      handlePrev();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", detectKey);

    return () => {
      document.removeEventListener("keydown", detectKey);
    };
  });

  useEffect(() => {
    const keyword =
      filteredCards[currentCardIndex].keywords[0].toLocaleLowerCase();

    if (!isWelcomeCardShowing) {
      switch (keyword) {
        case "daring":
          setColorArray({
            bgColor: "#CCA1DF",
            colorA: "#D8A5C3",
            colorB: "#B387C2",
          });
          break;
        case "laid back":
          setColorArray({
            bgColor: "#FFC9C8",
            colorA: "#F4BDBE",
            colorB: "#E695AE",
          });
          break;
        case "caring":
          setColorArray({
            bgColor: "#9BDDC5",
            colorA: "#95D2AB",
            colorB: "#42BABF",
          });

          break;
        case "work focused":
          setColorArray({
            bgColor: "#8CCFFF",
            colorA: "#9CD8F2",
            colorB: "#0A94E2",
          });

          break;
        case "energetic":
          setColorArray({
            bgColor: "#FFCBAD",
            colorA: "#F2C1A6",
            colorB: "#EE926A",
          });

          break;
        default:
          setColorArray({
            bgColor: "#F4CEB7",
            colorA: "#F8E7C5",
            colorB: "#CE6966",
          });
          break;
      }
    }
  }, [currentCardIndex, filteredCards, isWelcomeCardShowing]);

  // useEffect(() => {
  //   setCurrentCardIndex(0);

  //   if (isQuestions) {
  //     setFilteredCards(questionsCard);
  //   } else {
  //     setFilteredCards(activitysCard ? activitysCard : questionsCard);
  //   }
  // }, [isQuestions]);

  useEffect(() => {
    setCurrentCardIndex(0);
    const keyword = selectedKeyword.toLocaleLowerCase();
    switch (selectedKeyword) {
      case "Caring":
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(
          questionsCard.filter((card) => card.keywords[0] === keyword)
        );
        break;
      case "Daring":
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(
          questionsCard.filter((card) => card.keywords[0] === keyword)
        );
        break;
      case "Work focused":
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(
          questionsCard.filter((card) => card.keywords[0] === keyword)
        );
        break;
      case "Laid back":
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(
          questionsCard.filter((card) => card.keywords[0] === keyword)
        );
        break;
      case "Energetic":
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(
          questionsCard.filter((card) => card.keywords[0] === keyword)
        );
        break;
      default:
        // setFilteredCards(cards = cardData.activities.sort(() => (Math.random() > .5) ? 1 : -1));
        setFilteredCards(questionsCard);
        break;
    }
  }, [selectedKeyword]);

  return (
    <>
      <div id="animatedBackground">
        <Scene colors={colorArray} />
      </div>
      <div className="card-container">
        <Filter
          setSelectedKeyword={setSelectedKeyword}
          setIsQuestions={setIsQuestions}
          isQuestions={isQuestions}
        />
        {/* <button onClick={() => setIsQuestions(!isQuestions)} className="testbtn"/> */}
        <EnhancedSwipeableViews
          index={mod(currentCardIndex, filteredCards.length)}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents={true}
          slideRenderer={({ index, key }) => {
            if (isWelcomeCardShowing) {
              return <WelcomeCard />;
            } else {
              return (
                <div key={key}>
                  {isQuestions ? (
                    <Card
                      tabIndex={index === currentCardIndex ? 0 : -1}
                      type={
                        filteredCards[mod(index, filteredCards.length)]
                          .keywords[0]
                      }
                      text={
                        filteredCards[mod(index, filteredCards.length)].text
                      }
                      background={filteredCards[
                        mod(index, filteredCards.length)
                      ].keywords[0].toString()}
                    />
                  ) : (
                    <ActivityCard
                      type={
                        filteredCards[mod(index, filteredCards.length)]
                          .keywords[0]
                      }
                      title={
                        filteredCards[mod(index, filteredCards.length)].title
                      }
                      text={
                        filteredCards[mod(index, filteredCards.length)].text
                      }
                      setup={
                        filteredCards[mod(index, filteredCards.length)].setup
                      }
                      instructions={
                        filteredCards[mod(index, filteredCards.length)]
                          .instructions
                      }
                      time={
                        filteredCards[mod(index, filteredCards.length)].time
                      }
                      background={filteredCards[
                        mod(index, filteredCards.length)
                      ].keywords[0].toString()}
                      source={
                        filteredCards[mod(index, filteredCards.length)].source
                      }
                      tip={filteredCards[mod(index, filteredCards.length)].tip}
                      sourceUrl={
                        filteredCards[mod(index, filteredCards.length)]
                          .sourceUrl
                      }
                    />
                  )}
                </div>
              );
            }
          }}
        />
        <button className="arrow-left" onClick={handlePrev}>
          <img src="/images/arrow.png" alt="previous card" />
        </button>
        <button className="arrow-right" onClick={handleNext}>
          <img src="/images/arrow.png" alt="next card" />
        </button>
      </div>
    </>
  );
};

export default CardContainer;
